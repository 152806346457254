import 'react-datepicker/dist/react-datepicker.css'
import './Transactions.css'
import React, { memo, useState } from 'react'
import Loader from './Loader'
import { useAuthQuery } from '../helpers/http'
import { useRecoilState } from 'recoil'
import { filters_state } from '../states/filters.state'
import { IonToast } from '@ionic/react'

let Events: React.FC = memo(() => {
  let [showToast, setShowToast] = useState(false)
  let [toastMessage] = useState('')
  let [isAsc, setIsAsc] = useState(false)
  let [sortAttribute, setSortAttribute] = useState('horodatage')

  let [{ search }] = useRecoilState(filters_state)

  let { isLoading, isError, data: events } = useAuthQuery<any>('events', '/events')

  let filteredEvents = events?.filter((t: any) => {
    return t.machine_location?.toLowerCase().includes(search?.toLowerCase())
  })

  let filteredEventsSorted = filteredEvents?.sort((a: any, b: any): any => {
    let valueA = a[sortAttribute].toString()
    let valueB = b[sortAttribute].toString()
    if (isAsc) {
      return valueA > valueB ? 1 : -1
    } else {
      return valueA > valueB ? -1 : 1
    }
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError || !events) {
    return <div>Erreur</div>
  }

  return (
    <div className="tab_container container">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000} // Change this value to control how long the toast shows for
      />
      <div className="table_container">
        <table>
          <thead>
            <tr>
              <th
                className="center"
                onClick={() => {
                  setSortAttribute('horodatage')
                  setIsAsc(!isAsc)
                }}
              >
                Date
              </th>
              <th
                className="center"
                onClick={() => {
                  setSortAttribute('machineId')
                  setIsAsc(!isAsc)
                }}
              >
                Numéro
              </th>
              <th
                className="center"
                onClick={() => {
                  setSortAttribute('machine_location')
                  setIsAsc(!isAsc)
                }}
              >
                Localisation
              </th>
              <th
                className="left"
                onClick={() => {
                  setSortAttribute('typeAlarm')
                  setIsAsc(!isAsc)
                }}
              >
                Evènements
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredEventsSorted?.map((alerte: any) => {
              return (
                <tr>
                  <td className="center"> {new Date(alerte.horodatage * 1000).toLocaleString()}</td>
                  <td>{alerte.machineId}</td>
                  <td>{alerte.machine_location}</td>
                  <td>{alerte.typeAlarm}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      ></div>
    </div>
  )
})

export default Events
